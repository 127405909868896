import React, { useCallback, useContext } from 'react'
import useSmartExperiencePlace from 'components/Experiences/hooks/useSmartExperiencePlace'
import ExperienceOfferCarousel from './ExperienceOfferCarousel'
import Clickable from 'components/Common/Clickable'
import LineAngleDownIcon from 'components/Luxkit/Icons/line/LineAngleDownIcon'
import HeadingTextBlock from 'components/Luxkit/TextBlocks/HeadingTextBlock'
import ExperiencesPageLocationModal from 'components/Experiences/ExperiencesPage/ExperiencesPageLocationModal/ExperiencesPageLocationModal'
import ModalContext from 'contexts/ModalContext'
import IconButton from 'components/Luxkit/Button/IconButton'

function SmartExperienceOfferCarousel() {
  const place: App.Place | undefined = useSmartExperiencePlace()

  const showModal = useContext(ModalContext)

  const openLocationModal = useCallback(() => {
    showModal(<ExperiencesPageLocationModal />)
  }, [showModal])

  const ExperienceHeading = (
    <>
      Find experiences around&nbsp;
      <Clickable onClick={openLocationModal}>
        <HeadingTextBlock
          endIcon={
            <IconButton kind="tertiary" variant="dark" nonInteractable>
              <LineAngleDownIcon />
            </IconButton>
          }
          variant="heading2alt"
        >
          {place?.name}
        </HeadingTextBlock>
      </Clickable>
    </>
  )

  return (
    <ExperienceOfferCarousel
      place={place}
      experienceHeading={ExperienceHeading}
    />
  )
}

export default SmartExperienceOfferCarousel
