import React, { ComponentProps, useMemo } from 'react'
import { MAX_CAROUSEL_ITEM_COUNT } from './common/constants'
import { LOOKING_FOR_SOMETHING_ELSE_CONTENT } from '../LookingForSomethingElse/constants'
import { isManualSortingEnabledFor } from 'selectors/featuresSelectors'
import { ALL_HOTEL_TYPES, LE_CONTRACTED_HOTEL_TYPES } from 'constants/offer'
import OfferCarouselLookingForSomethingElse from '../LookingForSomethingElse/OfferCarouselLookingForSomethingElse'
import qs from 'qs'
import OfferCardCarouselSection from 'components/Common/OfferCardCarousel/OfferCardCarouselSection'

interface Props extends Pick<ComponentProps<typeof OfferCardCarouselSection>, 'tileStyle'>{
  sortBy?: 'value';
  title?: React.ReactNode;
}

function HotelOfferCarousel(props: Props) {
  const { sortBy, title, tileStyle } = props
  const isManualSortingEnabled = isManualSortingEnabledFor('carousel-hotel')

  const filters: App.OfferListFilters = useMemo(() => {
    if (isManualSortingEnabled && !sortBy) {
      return {
        offerTypes: ALL_HOTEL_TYPES,
        sortBy: 'carousel-hotel',
        limit: MAX_CAROUSEL_ITEM_COUNT,
      }
    } else {
      return {
        offerTypes: LE_CONTRACTED_HOTEL_TYPES,
        frontPageVisibleOnly: true,
        limit: MAX_CAROUSEL_ITEM_COUNT,
        sortBy,
      }
    }
  }, [isManualSortingEnabled, sortBy])

  const to = useMemo(() => {
    if (sortBy) {
      const query = {
        sortBy,
      }
      return `/hotels?${qs.stringify(query, { arrayFormat: 'comma' })}`
    }
    return LOOKING_FOR_SOMETHING_ELSE_CONTENT.hotels.to
  }, [sortBy])

  const headingPrefix = sortBy ? 'Best value' : 'Limited-time'

  return (
    <OfferCardCarouselSection
      filters={filters}
      title={title ?? <>{headingPrefix} <i>hotel </i>offers</>}
      allLink="/hotels"
      additionalFilters="holidayTypes"
      tileStyle={tileStyle}
    >
      <OfferCarouselLookingForSomethingElse to={to} />
    </OfferCardCarouselSection>
  )
}

HotelOfferCarousel.defaultProps = {
  tileStyle: 'card-medium',
}

export default React.memo(HotelOfferCarousel)
