import React, { useContext, useEffect, useMemo } from 'react'
import LayoutContainer from 'components/Common/LayoutContainer'
import Group from 'components/utils/Group'
import Heading from 'components/Luxkit/Typography/Heading'
import { useParams } from 'react-router'
import { capitalise } from 'lib/string/stringUtils'
import { HOME_PAGE_OFFER_TYPES, SupportedDestinations } from 'home/constants'
import Pane from 'components/Common/Pane'
import { ANYWHERE_PLACE_ID } from 'constants/search'
import GeoContext from 'contexts/geoContext'
import { getHomeOfferListParams } from 'home/helpers'
import { usePlaceByName } from 'hooks/usePlace'
import { MAX_CAROUSEL_ITEM_COUNT } from './common/constants'
import OfferListCarousel from 'components/OfferList/OfferListCarousel'
import { themeClassName } from 'lib/theme/themeUtils'
import config from 'constants/config'
import useOptimizelyExperiment from 'hooks/Optimizely/useOptimizelyExperiment'
import { OptimizelyExperiments } from 'constants/optimizely'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { fetchRegionRecommendations } from 'actions/RecommendationActions'
import useOfferList from 'hooks/Offers/useOfferList'
import { unique } from 'lib/array/arrayUtils'

interface Props {
  className?: string;
}

function HeroOfferCarouselSection(props: Props) {
  const { className } = props
  const { destination } = useParams<{ destination?: SupportedDestinations}>()
  const { currentRegionCode, geoStateName } = useContext(GeoContext)
  const destinationId = usePlaceByName(destination)?.id ?? ANYWHERE_PLACE_ID
  const dispatch = useAppDispatch()

  const filters = useMemo(() => {
    if (destination) {
      return {
        offerTypes: HOME_PAGE_OFFER_TYPES,
        limit: MAX_CAROUSEL_ITEM_COUNT,
        placeIds: [destinationId],
      }
    } else {
      return getHomeOfferListParams({
        region: currentRegionCode,
        subregion: geoStateName,
      })
    }
  }, [currentRegionCode, destination, destinationId, geoStateName])

  // --------- START: LERE NZ/US/GB Region recommendations on homepage (AB TEST) ----------
  const isLuxuryEscapes = config.BRAND === 'luxuryescapes'
  const isEligibleRegion = ['NZ', 'US', 'GB'].includes(currentRegionCode)
  const canTriggerExperiment = isLuxuryEscapes && isEligibleRegion
  const filteredOfferList = useOfferList(filters)
  const regionRecommendations = useAppSelector(state => state.recommendations.regionRecommendations)
  const regionRecommendationsExperimentEnabled = useOptimizelyExperiment(OptimizelyExperiments.lereRegionRecommendations, canTriggerExperiment) === 'on'
  const overrideOfferList: App.OfferList = useMemo(() => {
    if (regionRecommendationsExperimentEnabled) {
      const offers = unique([...regionRecommendations.offers.map(o => o.offerId), ...filteredOfferList.offerIds])
      const fetching = regionRecommendations.state === 'loading' || filteredOfferList.fetching
      return {
        offerIds: offers,
        key: 'lere-region-recommendations',
        fetching,
      }
    }
    return filteredOfferList
  }, [regionRecommendations, filteredOfferList, regionRecommendationsExperimentEnabled])

  useEffect(() => {
    if (regionRecommendationsExperimentEnabled) {
      dispatch(fetchRegionRecommendations())
    }
  }, [regionRecommendationsExperimentEnabled, dispatch])
  // --------- END: LERE NZ/US/GB Region recommendations on homepage (AB TEST) ------------

  return (
    <Pane paletteType="inverse" className={className}>
      <Group direction="vertical" gap={24}>
        <LayoutContainer>
          {!destination && <Heading variant="heading2">
            Today's top <i>exclusive</i> offers
          </Heading>}
          {destination && (
            <Heading variant="heading2">
              Today's top exclusive <i>{capitalise(destination)}</i> offers
            </Heading>
          )}
        </LayoutContainer>
        <OfferListCarousel
          overrideOfferList={overrideOfferList}
          filters={filters}
          className={themeClassName('inverse')}
          tileStyle="card-medium"
        />
      </Group>
    </Pane>
  )
}

export default React.memo(HeroOfferCarouselSection)
