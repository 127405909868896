import React, { useContext, useMemo } from 'react'
import { MAX_CAROUSEL_ITEM_COUNT } from './common/constants'
import { LOOKING_FOR_SOMETHING_ELSE_CONTENT } from '../LookingForSomethingElse/constants'
import { trendingCruiseDestinations } from '../TrendingDestinations/constants'
import { CRUISE_UNIFIED_FILTERS } from 'constants/cruise'
import { isManualSortingEnabledFor } from 'selectors/featuresSelectors'
import OfferCarouselLookingForSomethingElse from '../LookingForSomethingElse/OfferCarouselLookingForSomethingElse'
import OfferCardCarouselSection from 'components/Common/OfferCardCarousel/OfferCardCarouselSection'
import Places from 'constants/places'
import GeoContext from 'contexts/geoContext'
import config from 'constants/config'

const DEFAULT_FILTERS: App.OfferListFilters = {
  // for agent hub, we only support cruiseV1 for now
  offerTypes: config.agentHub?.isEnabled ? ['tour', 'hotel'] : ['cruise'],
  frontPageVisibleOnly: true,
  limit: MAX_CAROUSEL_ITEM_COUNT,
  destinationId: Places.Anywhere.id,
}

const ANYWHERE_DESTINATION = {
  name: 'Around the world',
  destinationId: Places.Anywhere.id,
}

const UNIFIED_FILTERS: App.OfferListFilters = {
  offerTypes: CRUISE_UNIFIED_FILTERS.offerTypes,
  holidayTypes: CRUISE_UNIFIED_FILTERS.holidayTypes,
  sortBy: CRUISE_UNIFIED_FILTERS.sortBy,
  limit: MAX_CAROUSEL_ITEM_COUNT,
  destinationId: Places.Anywhere.id,
}

interface Props {
  title?: React.ReactNode;
}

function CruiseOfferCarousel(props: Props) {
  const {
    title,
  } = props
  const filters = isManualSortingEnabledFor('cruises') ? UNIFIED_FILTERS : DEFAULT_FILTERS
  const { currentRegionCode } = useContext(GeoContext)
  const destinations = useMemo(() => {
    return [
      ANYWHERE_DESTINATION,
      ...(trendingCruiseDestinations[currentRegionCode] ?? []),
    ].map((dest) => ({
      value: dest.destinationId!,
      label: dest.name,
    }))
  }, [currentRegionCode])

  const to = useMemo(() => {
    return LOOKING_FOR_SOMETHING_ELSE_CONTENT.cruises.to
  }, [])

  return (
    <OfferCardCarouselSection
      filters={filters}
      title={title ?? <>Set sail on the ultimate <i>cruise</i></>}
      allLink="/cruises"
      additionalFilters="destinations"
      additionalFilterOptions={destinations}
      defaultFilter={Places.Anywhere.id}
      tileStyle="card-medium"
    >
      <OfferCarouselLookingForSomethingElse to={to} />
    </OfferCardCarouselSection>
  )
}

export default React.memo(CruiseOfferCarousel)
