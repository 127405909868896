export type LFSEOfferType = 'hotels'| 'tours' | 'ultralux'| 'experiences'| 'cruises'| 'villas'

type LookingForSomethingElseContent = {
  label: string;
  to: string;
  imageId?: string;
}

export const LOOKING_FOR_SOMETHING_ELSE_CONTENT: Record<LFSEOfferType, LookingForSomethingElseContent> = {
  hotels: { label: 'hotels', to: '/hotels', imageId: 'k58oh9ebyasvjo9ndff' },
  tours: { label: 'tours', to: '/tours', imageId: 'exu2capyq66fvv752yc4' },
  ultralux: { label: 'ultra lux', to: '/ultra-lux' },
  experiences: { label: 'experiences', to: '/experiences', imageId: 'ufils1y5jgf0yhvf5utk' },
  cruises: { label: 'cruises', to: '/cruises', imageId: 'wioy2m740nyf1gzxbr3v' },
  villas: { label: 'villas', to: '/homes-and-villas' },
}
