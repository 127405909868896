import TextButton from 'components/Luxkit/Button/TextButton'
import Group from 'components/utils/Group'
import Heading from 'components/Luxkit/Typography/Heading'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'

const Container = styled(Group)`
  background-color: ${props => props.theme.palette.neutral.default.seven};
  padding: ${rem(20)};
  width: ${rem(320)};

  ${mediaQueryUp.tablet} {
    padding: ${rem(40)};
  }

  ${mediaQueryUp.largeDesktop} {
    padding: ${rem(60)};
  }
`

interface Props {
  to: string;
}

function OfferCarouselLookingForSomethingElse(props: Props) {
  const { to } = props

  return (
    <Container direction="vertical" horizontalAlign="center" verticalAlign="center" gap={24}>
      <VerticalSpacer gap={8}>
        <Heading align="center" variant="heading5">Looking for something else?</Heading>
      </VerticalSpacer>
      <TextButton kind="primary" size="medium" shape="square" to={to}>
        View all
      </TextButton>
    </Container>
  )
}

export default React.memo(OfferCarouselLookingForSomethingElse)
