import React, { useMemo } from 'react'
import { rem } from 'polished'
import { connect } from 'react-redux'
import { getNavItems } from 'selectors/navigationSelectors'
import { getOfferTypesContent } from './constants'
import CategoryTile from 'components/Luxkit/CategoryTile'
import FullWidthCarousel from 'components/Luxkit/Carousel/FullWidthCarousel'

interface MappedProps {
  navItems: Array<App.NavItem>;
}

interface Props {
  disableActionButton?: boolean;
}

function OfferTypesCarousel({ navItems, disableActionButton = false }: MappedProps & Props) {
  const content = useMemo(() => getOfferTypesContent(navItems).filter(offerContent => offerContent.show), [navItems])

  return (
    <FullWidthCarousel
      itemsPerArrow={6}
      snap="start"
      gap={12}
      width={rem(180)}
    >
      {content.map((offerType) => (
        <CategoryTile
          key={offerType.to}
          heading={offerType.title}
          subHeading={offerType.description}
          align="top"
          callToAction={disableActionButton ? undefined : 'View offers'}
          to={disableActionButton ? undefined : offerType.to}
          image={offerType.image}
        />
      ))}
    </FullWidthCarousel>
  )
}

function mapStateToProps(state: App.State): MappedProps {
  return {
    navItems: getNavItems(state),
  }
}

export default connect(mapStateToProps)(OfferTypesCarousel)
