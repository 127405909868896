import TextButton from 'components/Luxkit/Button/TextButton'
import Group from 'components/utils/Group'
import Heading from 'components/Luxkit/Typography/Heading'
import React, { ReactNode } from 'react'

interface Props {
  allTo?: string;
  allHref?: string;
  children: ReactNode;
  variant?: React.ComponentProps<typeof Heading>['variant'];
}

function OfferCarouselHeader(props: Props) {
  const { allTo, allHref, children, variant = 'heading2' } = props

  return (
    <Group direction="horizontal" horizontalAlign="space-between" verticalAlign="start" gap={16}>
      <Heading variant={variant}>
        {children}
      </Heading>
      {!!(allTo || allHref) && <Group direction="horizontal" gap={16} verticalAlign="center">
        <TextButton kind="tertiary" size="medium" to={allTo} href={allHref}>
          View&nbsp;all
        </TextButton>
      </Group>}
    </Group>
  )
}

export default OfferCarouselHeader
