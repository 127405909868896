export function getOfferTypesContent(navItems: Array<App.NavItem>) {
  const showTile = (to: string) => navItems.find(navItem => navItem.to === to)

  return [
    {
      title: 'Hotels',
      description: 'The best hotels and resorts in the world',
      to: '/hotels',
      image: {
        id: 'k58oh9ebyasvjo9ndff',
      },
      show: showTile('/hotels'),
    },
    {
      title: 'Tours',
      description: 'Tours that do all the hard work for you',
      to: '/tours',
      image: {
        id: 'exu2capyq66fvv752yc4',
      },
      show: showTile('/tours'),

    },
    {
      title: 'Cruises',
      description: 'Thousands of itineraries all around the world',
      to: '/cruises',
      image: {
        id: 'wioy2m740nyf1gzxbr3v',
      },
      show: showTile('/cruises'),
    },
    {
      title: 'Ultra Lux',
      description: "Experience the world's most luxurious hotels, tours & cruises",
      to: '/ultra-lux',
      image: {
        id: '5rw20hquxqn9qozzg79p',
      },
      show: showTile('/ultra-lux'),
    },
    {
      title: 'Experiences',
      description: 'Immerse yourself in your destination',
      to: '/experiences',
      image: {
        id: 'ufils1y5jgf0yhvf5utk',
      },
      show: showTile('/experiences'),
    },
    {
      title: 'Flights',
      description: 'Your ticket to the world',
      to: '/flights',
      image: {
        id: 'fvp335n5vdcvhn6j1t4o',
      },
      show: showTile('/flights'),
    },
    {
      title: 'Car Hire',
      description: 'Making it easy to get from A to B',
      to: '/car-hire',
      image: {
        id: 'afof01h0vxhcgx9iygmn',
      },
      show: showTile('/car-hire'),
    },
  ]
}
